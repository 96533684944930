import React, { Component } from 'react'
import './App.css'

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Europa Kommunal</h1>
          <h2>
            <span role="img" aria-label="European Flag Emoji">
              🇪🇺
            </span>{' '}
            Ein Projekt von{' '}
            <a href="mailto:katharina.j.eckert@gmail.com">Katharina Eckert</a>
          </h2>
        </header>
      </div>
    )
  }
}

export default App
